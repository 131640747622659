@import "../../../components/App/App";

.between-stages-hoc {
  flex-direction: column;
  background-size: 100%;
  background-repeat: no-repeat;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: fit-content;
    padding: 20px 3% 5%;
    & a, & img, & > div {
      width: 42px;
      height: 36px;
    }
  }

  &__main {
    left: 50%;
    bottom: 5rem;
    transform: translate(-50%);
    position: absolute;
    padding-bottom: 6%;
    display: flex;
    justify-content: space-between;flex-direction: column;
    align-items: center;
    &__title {
      width: 362px;
      color:  #FFF;
      text-align: center;
      font-family: "Fraunces", serif;
      font-size: 2.375rem;
      font-style: normal;
      font-weight: 400;
      line-height: 120%;
      margin: 0 auto;
      white-space: pre-line;
      letter-spacing: -0.03em;
    }

    &__sub-title {
      opacity: .7;
      @include LG20;
      width: 360px;
      margin: 2% auto 0;
      white-space: pre-line;
    }

    & .button-container {
      margin-top: 1rem;

      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }

  &__footer {
    left: 50%;
    translate: -50%;
    position: fixed;
    bottom: 2rem;
    z-index: 2;

    & button {
      @include  MD16;
      width: 215px;
      min-height: 48px;
      padding: 12px 20px;
      justify-content: center;
      align-items: center;
      border-radius: 8px;
      background: var(--Shark, #FFF);
      margin-bottom: 5%;
      font-weight: 540;
    }
  }
}
