@import "../../../components/App/App";

.interests-page {
  background: var(--Tautog, #000);
  flex-direction: column;
  align-items: center;

  background-image: url(../../../../public/images/backgrounds/red-bg-center.svg);

  & header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: fit-content;
    padding: 20px 3% 5%;
    & a {
      width: 42px;
      height: 36px;
    }
    & img {
      width: 42px;
      height: 36px;
    }
  }
  & main {
    position: relative;
    padding: 0 8px 1rem;
    width: 100%;
    margin-bottom: 7rem;
  }

  & h2 {
    @include XL24;
  }

  h3 {
    @include  XXS10;
    align-self: center;
    margin-top: 2%;
    margin-bottom: 3%;
    color: var(--Gulper, #9A9EA6);
    text-align: center;
  }
  & footer {
    position: fixed;
    bottom: 2rem;

    & button {
      @include  MD16;
      width: 215px;
      min-height: 48px;
      padding: 12px 20px;
      justify-content: center;
      align-items: center;
      border-radius: 8px;
      background: var(--Shark, #FFF);
      margin-bottom: 5%;
      font-weight: 540;
          }}
}
