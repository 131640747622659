@keyframes label-go-up {
  0% {
    top: .9rem;
  }

  20% {
    top: .8rem;
  }

  30% {
    top: .7rem;
  }

  40% {
    top: .6rem;
  }

  50% {
    top: .5rem;
  }

  60% {
    top: .4rem;
  }

  70% {
    top: .3rem;
  }

  80% {
    top: .2rem;
  }

  90% {
    font-size: .9rem;
    top: .1rem;
  }

  100% {
    font-size: .8rem;
    top: 0;
  }
}

.ui-text-field {
  font-family: inherit;
  height: 5rem;

  &__inner {
    height: 4rem;

    padding: .25rem 1rem;

    border-radius: .5rem;
    border: .5px solid;
    border-image: linear-gradient(87.36deg, rgba(65, 67, 70, 0.1) 23.06%, #6E727B 47.12%, rgba(65, 67, 70, 0.1) 81.03%) 10;
    
    font-size: 1.125rem;
    font-family: inherit;

    &.invalid {
      border: 1.5px solid;
      border-color: #FE3838 !important;
      border-image: none;

      & input {
        color: #FE3838;
      }
    }
    
    &:focus-within {
      border: 1.5px solid;
      border-color: var(--Shark, #FFF);
      border-image: none;
    }

    &__label {
      width: 100%;
      position: relative;
      top: 1rem;

      font-size: 1rem;
      font-family: inherit;
      color: var(--Gulper, #9A9EA6);

      &.up {
        animation: label-go-up .1s ease-in forwards;
      }
    }

    &__input {
      width: 100%;
      font-family: inherit;

      color: var(--Shark, #FFF);
    }

    &__eye {
      width: 1.125rem;
      height: 1.125rem;
      position: relative;
      top: -1.75rem;
      left: 100%;
      transform: translate(-100%);
    }
  }

  &__error {
    margin: .250rem 0 0 1rem;
    font-size: .75rem;
    color: #FE3838;
  }
}
