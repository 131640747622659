$element-width: 25rem;

.ui-carousel {
  &__window {
    display: flex;
    max-width: 100vw;

    overflow: auto;

    &__card {
      flex: 0 0 auto;
      width: 19rem;
      min-height: 13rem;

      margin: 0 .5rem;
      border-radius: 1rem;
      border-left: .5px solid;
      border-right: .5px solid;
      border-image: linear-gradient(180deg, rgba(65, 67, 70, 0.1) 23.06%, #6E727B 47.12%, rgba(65, 67, 70, 0.1) 81.03%) 10;

      background: linear-gradient(90deg, #484a4d34 20%, #14151743 30%, #3b3d4243 99%, #5e616544 100%);

      &.ui-carousel__window__card_transparent {
        border-image: none;
        background: none;
      }
    }
  }

  &__points {
    text-align: center;

    &__point {
      margin: 0 .125rem;
    }
  }
}
