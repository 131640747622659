.ui-package-propositions {
  &__body {
    padding: 1.25rem 2rem;
    width: 100%;

    &__proposition {
      &__line {
        width: 85%;
        margin: 1rem 0rem 1rem 3rem;
        border: .125px solid rgba(240, 241, 245, 0.25);
      }
    }
  }
}
