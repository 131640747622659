@keyframes close {
  0% {
    
  }
}

@keyframes blur {
  0% {
    transform: scale(0);
    backdrop-filter: blur(0);
  }

  100% {
    transform: scale(1);
    backdrop-filter: blur(10px);
  }
}


@keyframes popup {
  0% {
    transform: translate(0, 100%);
  }

  100% {
    transform: translate(0, 0);
  }
}

.paywall-offer-decline-popup {
  z-index: 2;
  position: fixed;
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 450px;
  height: 100%;

  padding: .5rem;

  &__content {
    width: 100%;

    padding: 1.5rem;

    border-radius: .5rem;

    background-color: var(--Shark, #FFF);
    background-image: url(../../../../../public/images/backgrounds/dots-gray.svg);
    background-position: 50% -7rem;
    background-repeat: no-repeat;
    background-size: 125%;

    & * {
      font-weight: 500;
    }

    // &__cross {
    //   position: relative;
    //   left: calc(100% - 1rem);
    //   top: -.75rem;
    //   font-size: 2rem;
    // }

    &__files {
      width: fit-content;

      margin: 3rem auto 0 auto;
      padding: .75rem;
      border-radius: 35%;

      background-color: var(--Shark, #FFF);

      box-shadow: 0px .125rem 23.357818603515625px 0px rgba(0, 0, 0, 0.1);

      &__inner {
        display: flex;
        justify-content: center;
        align-items: center;

        padding: .5rem;
        border-radius: 35%;
        background-color: #FE3838;
        width: 5.5rem;
        height: 5.5rem;

        img {
          width: 3rem;
        }
      }
    }

    &__about-offer-miss {
      margin: 3rem 0 0 0;
      font-size: 2rem;
      text-align: center;
      letter-spacing: -0.02em;
    }

    &__is-sure-to-proceed {
      margin: 0 0 3rem 0;
      text-align: center;
      color: var(--Gulper, #9A9EA6);
    }

    &__footer {
      display: flex;
      justify-content: space-between;
      align-items: center;

      &__sure {
        color: #FE3838;
        font-size: 16px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: -0.02em;
        text-align: left;

        text-decoration: underline;
      }

      &__give-offer {
        padding: .75rem 1rem;

        border-radius: .5rem;

        color: var(--Shark, #FFF);
        background: #1b1c1f;
      }
    }
  }

  &.open{
    backdrop-filter: blur(10px);
    animation: blur .3s;

    .paywall-offer-decline-popup__content {
      animation: popup .3s;
    }
  }

  &.closed {
    transform: scale(0);
  }
}
