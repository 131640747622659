.auth-page {
  background-color: var(--Tautog, #000);
  flex-direction: column;
  align-items: center;
  position: relative;
  overflow: hidden;

  background-image: url(../../../public/images/backgrounds/red-bg-center.svg);
  background-repeat: repeat;
  background-position: center 15rem;
  background-size: 100% 60%;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: fit-content;
    padding: 20px 3% 5%;

    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;

    z-index: 2;
    & > * {
      width: 42px;
      height: 36px;
    }

    & .logo {
      width: 42px;
      height: 36px;
    }
  }
  &__main {
    position: relative;
    display: flex;

    flex-direction: column;
    justify-content: end;

    width: 100%;
    height: 75svh;

    padding: 0 1rem;

    &__text {
      &__title {
        color: var(--Shark, #FFF);
        text-align: center;

        font-family: Fraunces;
        font-size: 2.375rem;
        font-style: normal;
        font-weight: 400;
        line-height: 120%;
        letter-spacing: -0.38px;
      }

      &__label {
        color: var(--Shark, #FFF);
        text-align: center;

        font-family: Inter;
        font-size: 1.25rem;
        font-style: normal;
        font-weight: 400;
        line-height: 130%;
      }
    }

    &__login-options {
      display: flex;
      flex-direction: column;

      width: 90%;
      margin: 2rem auto 0 auto;

      & > * {
        margin: .5rem 0;
        font-family: "DM Sans", sans-serif;
        font-size: 1.2rem;
        font-style: normal;
        font-weight: 400;
        line-height: 125%;
        letter-spacing: -0.32px;
        color: var(--Shark, #FFF);
      }
    }
  }
}
