@keyframes from-top-appear {
  0% {
    top: -100%;
  }

  100% {
    top: 1rem;
  }
}

.snakbar {
  position: fixed;

  display: flex;
  align-items: center;

  z-index: 3;

  top: -100%;
  left: 50%;
  transform: translate(-50%);

  padding: 1rem;

  background-color: var(--Shark, #FFF);

  border-radius: 10rem;

  &.active {
    animation: from-top-appear .3s forwards;
  }

  img {
    margin-right: 1rem;
  }

  &__text {
    color: var(--Tuna, #1B1C1F);
    font-weight: 500;

    text-wrap: nowrap;
  }
}
