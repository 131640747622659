@keyframes fadeInSequence {
  0% {
    --top-opacity: 0;
    --bottom-opacity: 0;
  }
  10% {
    --top-opacity: .2;
    --bottom-opacity: 0;
  }
  20% {
    --top-opacity: .4;
    --bottom-opacity: 0;
  }
  30% {
    --top-opacity: .6;
    --bottom-opacity: 0;
  }
  40% {
    --top-opacity: .8;
    --bottom-opacity: 0;
  }
  50% {
    --top-opacity: 1;
    --bottom-opacity: 0;
  }
  60% {
    --top-opacity: 1;
    --bottom-opacity: .2;
  }
  70% {
    --top-opacity: 1;
    --bottom-opacity: .4;
  }
  80% {
    --top-opacity: 1;
    --bottom-opacity: .6;
  }
  90% {
    --top-opacity: 1;
    --bottom-opacity: .8;
  }
  100% {
    --top-opacity: 1;
    --bottom-opacity: 1;
  }
}

.special-offer {
  position: relative;
  width: 100%;
  bottom: 0;
  max-width: 450px;

  --animation-name: popup;
  --animation-duration: .5s;
  animation: var(--animation-name) ease forwards;
  animation-duration: var(--animation-duration);

  border-radius: .5rem;

  color: var(--Shark, #FFF);

  &__top {
    opacity: var(--top-opacity);

    &__title {
      text-align: center;
    }
  
    &__pricing {
      position: relative;
      overflow: hidden;
      display: flex;
      justify-content: space-between;
      padding: 1rem;
      border-radius: 2rem;
  
      background-image: url(../../../../public/images/backgrounds/bg-gradient-red-metal-border.svg);
      background-repeat: no-repeat;
      background-size: 100%;
  
      &::before {
        content: '';
        position: absolute;
  
        top: 0;
        left: 0;
  
        width: 100%;
        height: 100%;
  
        background: linear-gradient(90deg, rgba(0, 0, 0, 0.08) 0%, rgba(254, 56, 56, 0.08) 50%);
      }
  
      &__price-description {
        margin-left: 1rem;
      }
  
      &__discount {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
  
        width: 5.25rem;
        height: 5.25rem;
  
        border-radius: 1.25rem;
        background-image: url(../../../../public/images/backgrounds/bg-gradient-red-metal-border.svg);
  
        font-size: 1.5rem;
        font-weight: 600;
      }
    }
  
    &__timer {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }


  &__bottom {
    opacity: var(--bottom-opacity);

    &__claim-offer {
      font-family: Inter;
      font-style: normal;
      padding: 1rem 0;
      width: 100%;
      border-radius: .5rem;
  
      background-color: #fe3838;
  
      color: var(--Shark, #FFF);
      font-size: 1.5rem;
      font-weight: 600;
      line-height: 125%;
  
      &__loader {
        width: fit-content;
        margin: 0 auto;
      }
    }
  
    &__pay-as-go {
        margin: .75rem 0 .25rem 0;
        font-size: .875rem;
        font-weight: 540;
        font-style: normal;
        line-height: 142.857%;
        letter-spacing: -0.05em;
        text-align: center;
        color: var(--Gulper, #AFAFAF);
    }
  }
}
