@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&family=Inter:wght@100..900&family=Fraunces:opsz,wght,SOFT@9..144,100..900,0..100&display=swap');

/* Reset and base styles  */

* {
    padding: 0px;
    margin: 0px;
    border: none;
}

*,
*::before,
*::after {
    box-sizing: border-box;
}

/* Links */

a, a:link, a:visited  {
    text-decoration: none;
}

a:hover  {
    text-decoration: none;
}

/* Common */

aside, nav, footer, header, section, main {
    display: block;
}

h1, h2, h3, h4, h5, h6, p, span {
    font-size: inherit;
    font-weight: inherit;
    font-family: "Inter", sans-serif;
}

ul, ul li {
    list-style: none;
    margin: 0;
    padding:0;
}

img {
    vertical-align: top;
}

img, svg {
    max-width: 100%;
    height: auto;
}

address {
    font-style: normal;
}

/* Form */

input, textarea, button, select {
    font-size: inherit;
    color: inherit;
    background-color: transparent;
}



button, input[type="submit"] {
    display: inline-block;
    box-shadow: none;
    background-color: transparent;
    background: none;
    cursor: pointer;
}

input:focus, input:active,
button:focus, button:active {
    outline: none;
}

button::-moz-focus-inner {
    padding: 0;
    border: 0;
}

label {
    cursor: pointer;
}

legend {
    display: block;
}

p {
    margin: 0;
}

#root {
    overflow: hidden;
}

::-webkit-scrollbar {
    width: 0;
    background: transparent;
}

.page {
    display: flex;
    min-height: 100svh;
    max-width: 100svh;
    overflow: hidden;
    max-width: 450px;
    margin:  auto;
    position: relative;
}

body {
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 0;
    overflow: auto;
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    &:focus {
        outline: none !important;
    }
    background: #000000;

    font-family: "Inter", sans-serif;
}

:root {
    --Shark: #fff;
    --Tautog: #000;
}

@mixin XXS10 {
    font-size: 10px;
    font-style: normal;
    font-weight: 600;
    line-height: 160%;
    letter-spacing: 1.2px;
    text-transform: uppercase;
}

@mixin LG20 {
    color: var(--Shark, #FFF);
    font-size: 20px;
    font-weight: 400;
    font-style: normal;
    line-height: 26px;
    letter-spacing: -0.02em;
    text-align: center;
}

@mixin MD16 {
    color: var(--Tuna, #1B1C1F);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    line-height: 20px;
    letter-spacing: -.5px;
}

@mixin SM14 {
    color: var(--Gulper, #9A9EA6);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 128.571%;
    letter-spacing: -0.28px;
}


@mixin XS12 {
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 133.333%;
    letter-spacing: -0.02em;
    color: var(--Shark, #FFF);
    text-align: center;
}

@mixin XL24 {
    color: var(--Shark, #FFF);
    text-align: center;

    font-family: Inter;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 30px;
}

@mixin XXXL32 {
    color: var(--Shark, #FFF);
    text-align: center;
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: 36px;
    letter-spacing: -2%;
}

@mixin XXXL36 {
    color: var(--Shark, #FFF);
    font-size: 2.25rem;
    font-weight: 300;
    line-height: 36px;
    text-align: center;
    font-variation-settings: 'SOFT' 53, 'WONK' 1;
}

.display-none {
    display: none;
}

.fade-in {
    opacity: 0;
    animation: fadeIn 0.5s ease-in forwards;
    z-index: 2;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

$colors: (
    "golden": #ffbf00,
    "green": #00802B,
    "green-350": #47D778,
    "gray": var(--Gulper, #9A9EA6),
    "dase": #FE3838,
    "white": var(--Shark, #FFF),
    "tuna": var(--Tuna, #1B1C1F),
);
$gradients: (
    'yellow-to-gold': linear-gradient(90deg, #FFDB70 0%, #FFBF00 100%),

);
$text-aligns: (start, center, end);
$text-decorations: (dashed, dotted, double, line-through, none, overline, solid, underline, wavy);

@each $color, $value in $colors {
    .text-#{$color} {
        color: $value;
    }
}

@each $gradient, $value in $gradients {
    .text-#{$gradient} {
        background: $value;
        background-clip: text;
        -webkit-text-fill-color: transparent;
    }
}

@each $text-align in $text-aligns {
    .text-align-#{$text-align} {
        text-align: $text-align;
    }
}

@each $text-decoration in $text-decorations {
    .text-#{$text-decoration} {
        text-decoration: $text-decoration;
    }
}

.text-XXS10 {
    @include XXS10;
}

.text-MD16 {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -2%;
    text-align: center;
}
