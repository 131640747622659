@import "../../../App/App";

@mixin btn {
  border: 1px solid;

  outline: none;
  position: relative;
  display: flex;
  padding: 1.25rem;
  justify-content: space-between;
  align-items: center;
  z-index: 1;
  border-radius: var(--Corner-Radius-SM, 16px);
  background-color: #1b1c1f4d;
  cursor: pointer;
  margin: 10px 0;
}

@mixin btn-before {
  content: "";
  position: absolute;
  left: 1px;
  right: 1px;
  top: 1px;
  bottom: 1px;
  border-radius: var(--Corner-Radius-SM, 16px);
  z-index: -1;
  transition: 200ms
       }

@mixin btn-after {
  -webkit-background-clip: text;
  color: transparent;
  transition: 200ms;
  border-radius: var(--Corner-Radius-SM, 16px);
}

@mixin btn-hover-before {
  opacity: 100%;
  top: 1px;
  right: 1px;
  bottom: 1px;
  left: 1px;
  border-radius: var(--Corner-Radius-SM, 16px);
}

@mixin btn-hover-after {
  color: white;
  border-radius: var(--Corner-Radius-SM, 16px);
}



.choice-button {
 @include btn;
 border-image: linear-gradient(87.36deg, rgba(65, 67, 70, 0.1) 23.06%, #6E727B 47.12%, rgba(65, 67, 70, 0.1) 81.03%) 10;
  &__emoji {
    color: var(--Shark, #FFF);
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.64px;
    margin-right: 8px;
  }
  &__title {
    @include  MD16;
    color: var(--Shark, #FFF);
  }
  &__subtitle {
    @include  SM14;
    color: var(--Gulper, #9A9EA6);
    margin-top: 4px;
  }
  &__checkbox {

  }
  &__container {
    display: flex;
    align-items: center;
  }
}

.choice-button::before {
  @include  btn-before
}
.choice-button::after {
  @include  btn-after
}
.choice-button:hover::before {
  @include  btn-hover-before
}
.choice-button:hover::after{
  @include  btn-hover-after
}

.choice-button-selected {
  @include btn;
  border-color: var(--Shark, #FFF);
}
