@import "../../components/App/App";

@keyframes spin {
  100% { transform: rotate(360deg); }
}

.welcome-aboard-page {
  background-color: var(--Tautog, #000);
  flex-direction: column;
  align-items: center;
  position: relative;
  overflow: hidden;

  background-image: url(../../../public/images/backgrounds/red-bg-center.svg);
  background-position: center;

  &::before {
    content: "";
    width: 100vh;
    height: 100vh;
    position: absolute;
    background-image: url(../../../public/images/backgrounds/dots-red-blue-yellow.svg);
    background-repeat: no-repeat;
    background-position: center;
    animation: spin 80s linear infinite;
  }
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: fit-content;
    padding: 20px 3% 5%;

    background-image: url(../../../public/images/backgrounds/red-bg-top.svg);
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;

    z-index: 2;
    & > * {
      width: 42px;
      height: 36px;
    }

    & .logo {
      width: 42px;
      height: 36px;
    }
  }
  &__main {
    display: flex;

    width: 100%;
    height: 90svh;

    justify-content: center;
    flex-direction: column;
    position: relative;

    &__app-store-download-btn {
      margin: 2rem auto;
    }
  }

  & h2 {
    @include  XL24;
  }

  & h3 {
    @include  MD16;
    align-self: stretch;
    margin-top: 2%;
    color: var(--Gulper, #9A9EA6);
    margin-bottom: 4%;
  }

}
