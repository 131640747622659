.auth-loading-access-denied {
  display: flex;
  justify-content: center;
  align-items: center;

  h2 {
    font-size: 2rem;
    color: #FE3838;
  }

  h3 {
    color: var(--Shark, #FFF);
  }
}
