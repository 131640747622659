@import "../../../components/App/App";

.we-help-with-hoc {
  display: flex;
  flex-direction: column;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: fit-content;
    padding: 20px 3% 5%;

    background-image: url(../../../../public/images/backgrounds/red-bg-top.svg);
    background-size: 80%;
    background-position: center;
    background-repeat: no-repeat;

    & a, & img, & > div {
      width: 42px;
      height: 36px;
    }
  }

  &__main {
    &__top {
      &__label {
        text-align: center;
      }
  
      &__title {
        padding: 0 .75rem;
        white-space: pre-line;
      }
    }

    &__main-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;
      margin-top: 1rem;
      height: 62.5svh;
      overflow: hidden;

      &::after {
        content: '';
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        position: absolute;
        box-shadow: inset 0 -7.5rem 7.5rem 1rem #000;
      }
    }
  }

  &__footer {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    left: 50%;
    translate: -50%;
    position: fixed;
    bottom: 2rem;

    &__question {
      text-align: center;
      font-size: 1.25rem;
      white-space: pre-line;
      opacity: .7;
      color: var(--Shark, #FFF);
    }

    &__button {
      @include  MD16;
      width: 215px;
      min-height: 48px;
      padding: 12px 20px;
      justify-content: center;
      align-items: center;
      border-radius: 8px;
      background: var(--Shark, #FFF);
      margin-bottom: 2.5%;
      font-weight: 540;
    }
  } 

  & h2 {
    @include  XL24;
    letter-spacing: -0.05em;
  }

  & h3 {
    @include  XXS10;
    align-self: stretch;
    margin-top: 2%;
    color: var(--Gulper, #9A9EA6);
    margin-bottom: 3%;
  }
}
