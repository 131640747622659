.paywall-guide-slide {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  width: 100svw;
  max-width: 450px;
  height: 20rem;

  &__image {
    height: 80%;
    object-fit: cover;
    margin-bottom: .75rem;
  }

  &__label {
    color: var(--Shark, #FFF);
    text-align: center;

    font-family: Inter;
    font-size: 28px;
    font-style: normal;
    font-weight: 600;
    line-height: 114.286%;
    letter-spacing: -0.56px;
  }
}
