@import "../../../../components/App/App";

.bar-chart {
  display: flex;
  flex-direction: column;
  justify-content: end;

  height: 7rem;
  width: 2.5rem;

  &__digit-indicator {
    margin: 0 auto;
    @include  XS12
  }

  &__body {
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    background-color: var(--Shark, #FFF);
  }
}
