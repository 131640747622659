@import "../../components/App/App";
@import "../../components/UI/Buttons/ChoiceButton/ChoiceButton";

.video-effectiveness-page {
  background: var(--Tautog, #000);
  flex-direction: column;
  align-items: center;
  
  background-image: url(../../../public/images/backgrounds/red-bg-center.svg);
  background-repeat: repeat;
  background-position: center 20rem;
  background-size: 100% 80%;

  & header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: fit-content;
    padding: 20px 3% 5%;
    & a {
      width: 42px;
      height: 36px;
    }
    & img {
      width: 42px;
      height: 36px;
    }
  }

  &__main {
    display: flex;
    flex-direction: column;
    align-items: center;

    margin-bottom: 5rem;
    padding: .75rem;

    height: 75%;
    position: relative;

    & > &__title {
      font-size: 1.5rem;
      font-weight: 500;
      letter-spacing: -0.02em;
    }

    &__card {
      outline: none;
      position: relative;
      z-index: 1;

      border: .5px solid;

      border-image: linear-gradient(87.36deg, rgba(65, 67, 70, 0.1) 23.06%, #6E727B 47.12%, rgba(65, 67, 70, 0.1) 81.03%) 10;

      background: linear-gradient(to left,  #14111133 15%, #48484c33 50%, #14111133 85%);
      margin-top: 7rem;
      height: 80%;
      padding: 2rem 2rem;

      &__charts {
        display: flex;

        position: relative;
        color: var(--Shark, #FFF);

        &__retention {
          @include XXS10;
          transform: rotate(-90deg);
          width: 3rem;
          height: 100%;

          margin: 4rem 1rem 0 -1rem;
        }

        &__video {
          text-align: center;
          margin: 0 2rem 0 .25rem;
        }

        &__text {
          text-align: center;
          opacity: .2;
        }

        &__hr {
          position: relative;
          width: 100%;
          height: 0px;
          border-top: .5px solid;

          top: 7rem;
          left: -10rem;

          border-image: linear-gradient(87.36deg, rgba(65, 67, 70, 0.1) 5%, #6E727B 70%, rgba(65, 67, 70, 0.1) 95%) 10;
        }

        & .people {
          border-radius: 16.6px;
          // transform: rotate(2.113deg);
          width: 40%;
          position: absolute;
          right: -5%;
          bottom: 0;
        }
      }

      &__texts {
        margin-top: 35px;

        & > &__title {
          font-size: 1.25rem;
          color: var(--Shark, #FFF);
        }

        &__gitnux {
          & > &__researches {
            font-size: .875rem;
            margin-top: .75rem;
          }
        }

        &__edinburg {
          display: flex;

          &__logo {
           width: 20%;

            & img {
              width: 60px;
              height: 60px;
            }

            margin-right: .5rem;
          }

          &__research {
            width: 80%;

            & p {
              @include SM14;
              color: var(--Gulper, #9A9EA6);
              & span {
                color: var(--Shark, #FFF);
              }
            }
          }
        }
      }

    }
    &__card::before {
      @include  btn-before

    }
    &__card::after {
      @include  btn-after
    }
    &__card:hover::before {
      @include  btn-hover-before
    }
    &__card:hover::after{
      @include  btn-hover-after
    }
  }

  & h2 {
    @include XXXL32;
    text-align: start;
    color: var(--Shark, #FFF);
  }

  & h3 {
    @include MD16;
    align-self: stretch;
    color: var(--Gulper, #9A9EA6);
    margin-bottom: 24px;
  }

  & h4 {
    @include MD16;
    color: var(--Shark, #FFF);
    margin-bottom: 16px;
  }

  & footer {
    position: fixed;
    bottom: 2rem;
    z-index: 2;

    & button {
      @include MD16;
      width: 215px;
      min-height: 48px;
      padding: 12px 20px;
      justify-content: center;
      align-items: center;
      border-radius: 8px;
      background: var(--Shark, #FFF);
      margin-bottom: 5%;

          }
  }
}

.bar-description {
  @include XXS10;
  margin-top: .25rem;
}
