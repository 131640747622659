.review-card {
  margin: 1rem .5rem;
  border-radius: 1rem;
  border-left: .5px solid;
  border-right: .5px solid;
  border-image: linear-gradient(180deg, rgba(65, 67, 70, 0.1) 23.06%, #6E727B 47.12%, rgba(65, 67, 70, 0.1) 81.03%) 10;

  background: linear-gradient(90deg, #484a4d34 20%, #14151743 30%, #3b3d4243 99%, #5e616544 100%);

  &__card {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 2rem 1.5rem;

    background-image: url(../../../../../public/images/icons/quote-start.svg);
    background-repeat: no-repeat;
    background-size: 2rem;
    background-position: 1.5rem 1.5rem;
  }
}
