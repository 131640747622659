.ui-timer {
  --bg-color: transparent;
  --text-color: var(--Shark, #FFF);

  display: flex;
  justify-content: center;

  border-radius: 1rem;
  background-color: var(--bg-color);

  padding: .5rem 0;

  font-family: Inter;
  font-size: 1.25rem;
  font-weight: 600;
  letter-spacing: -0.02em;
  line-height: 130%;
  text-align: center;
  width: 6rem;

  color: var(--text-color);

  &__hours, &__minutes, &__seconds {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 2rem;
    height: 2rem;

    font-size: inherit;

    border-radius: .5rem;
  }
}
