.dark-shiny-button {
  display: flex;
  justify-content: space-between;
  border-radius: 1rem;
  border: .5px solid;

  border-image: linear-gradient(87.36deg, rgba(65, 67, 70, 0.1) 23.06%, #6E727B 47.12%, rgba(65, 67, 70, 0.1) 81.03%) 10;

  padding: 1rem 2rem;

  background-color: var(--Opacity-Marlin, #1b1c1f4d);
  backdrop-filter: blur(.8rem);

  & > span {
    font: inherit;
    color: inherit;
  }
}
