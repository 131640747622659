.review-card-inner {
  h3.review-card-inner__comment {
    margin: 0;
    color: var(--Shark, #FFF);
    font-weight: 400;
    letter-spacing: -0.04em;
    font-size: 1rem;
    white-space: pre-line;
  }

  &__footer {
    display: flex;
  
    &__reviewer-avatar {
      width: 2.75rem;
      height: 2.75rem;

      margin-right: 1rem;
    }

    &__right {
      &__stars {
        line-height: .75rem;
        & > * {
          width: .75rem;
          margin: 0 .125rem;
        }
      }

      & &__reviewer-name {
        margin: 0;
        font-weight: 450;
        letter-spacing: -0.02em;
        font-size: 1rem;
        color: var(--Shark, #FFF);
      }

      &__label {
        font-size: .75rem;
        color: var(--Shark, #FFF);
        opacity: .6;
        font-weight: 400;
        line-height: 16px;
        font-size: 12px;
        letter-spacing: -0.04em;
      }
    }
  }
}
