@import "../../components/App/App";

@keyframes spin {
  100% { transform: rotate(360deg); }
}

.personal-brand-step1-page {
  flex-direction: column;
  align-items: center;
  position: relative;
  overflow: hidden;

  background-image: url(../../../public/images/backgrounds/forest-coffeeman.png);
  background-size: 100%;
  background-repeat: no-repeat;

  &::before {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    background: linear-gradient(180deg, #00000000 20%, #00000050 30%, #000000 60%, #000000 100%);
  }
  & header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: fit-content;
    padding: 20px 3% 5%;
    z-index: 2;
    & a {
      width: 42px;
      height: 36px;
    }
    & img {
      width: 42px;
      height: 36px;
    }
  }
  &__main {
    position: relative;
    display: flex;

    width: 100%;
    height: 75svh;

    margin-bottom: 8rem;

    justify-content: end;
    flex-direction: column;
    position: relative;

    &__text {
      bottom: 2rem;

      position: absolute;
      width: 100%;

      &__top {
        line-height: 4rem !important;
        font-size: 4rem !important;
      }

      &__bottom {
        margin-top: 1.125rem !important;
        opacity: .7;
      }
    }
  }

  & h2 {
    @include  XXXL36;
    font-family: Fraunces;
  }

  & h3 {
    @include  LG20;
    align-self: stretch;
    margin-top: 2%;
    color: var(--Shark, #FFF);
    margin-bottom: 4%;
  }
  & footer {
    position: absolute;
    bottom: 6rem;

    & button {
      @include  MD16;
      min-height: 48px;
      padding: 12px 20px;
      white-space: nowrap;
      border-radius: 8px;
      background: var(--Shark, #FFF);
      font-weight: 500;
      font-family: "Inter", sans-serif;
    }
  }
}
