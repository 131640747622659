.plain-auth-step-2-page {
  background-color: var(--Tautog, #000);
  flex-direction: column;
  align-items: center;
  position: relative;
  overflow: hidden;

  background-image: url(../../../../../public/images/backgrounds/red-bg-center.svg);
  background-repeat: repeat;
  background-position: center 10rem;
  background-size: 100% 60%;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: fit-content;
    padding: 20px 3% 5%;

    z-index: 2;
    & > * {
      width: 42px;
      height: 36px;
    }

    & .logo {
      width: 42px;
      height: 36px;
    }
  }
  &__main {
    display: flex;

    justify-content: end;
    flex-direction: column;

    width: 100%;
    height: 75svh;

    padding: 0 1rem;

    margin-top: 2rem;

    &__title {
      color: var(--Shark, #FFF);
      font-family: Fraunces;
      line-height: 2.35rem;
      font-size: 2.25rem;
      font-weight: 400;
      letter-spacing: -0.01em;
      text-align: center;

      margin-top: 15rem;
      margin-bottom: .5rem;
    }

    &__fields {
      margin: .5rem .2rem;
      font-family: "Inter", sans-serif;
    }

    &__continue-btn {
      margin: .75rem 0 1rem 0;
      padding: 1rem;

      border-radius: .5rem;
      background-color: var(--Shark, #FFF);

      font-size: 1.5rem;
      font-weight: 500;
      font-family: "Inter", sans-serif;
      letter-spacing: -0.02em;

      &:disabled {
        opacity: .5;
      }
    }
  }
}
