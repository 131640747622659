@import "../../../components/App/App";

.auth-loading {
  background: var(--Tautog, #000);
  flex-direction: column;
  align-items: center;

  background-image: url(../../../../public/images/backgrounds/red-bg-center.svg);
  background-position: center;

  & header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: fit-content;
    padding: 20px 3% 5%;

    background-image: url(../../../../public/images/backgrounds/red-bg-top.svg);
    background-size: 80%;
    background-position: center;
    background-repeat: no-repeat;

    & a, & img, & > div {
      width: 42px;
      height: 36px;
    }
  }

  &__main {
    z-index: 1;
    position: relative;
    width: 100%;
    padding: 0 1rem;

    margin-bottom: 7rem;

    &__before-after {
      display: flex;
      justify-content: space-between;

      padding: 0 1.25rem;

      color: #fff;
      font-size: 1.5rem;
      font-weight: 500;

      & > div {
        display: flex;
        align-items: center;
      }

      &__emoji {
        font-size: 3rem;
      }
    }
  }

  & h2 {
    @include  XL24;
  }

  & h3 {
    @include XXS10;
    align-self: stretch;
    margin-top: 2%;
    margin-bottom: 3%;
    color: var(--Gulper, #9A9EA6);
    text-align: center;
  }
}
