@import "../../components/App/App";

.pay-now {
  position: relative;
  background: var(--Tautog, #000);
  flex-direction: column;
  align-items: center;

  background-image: url(../../../public/images/backgrounds/red-bg-center.svg);
  background-position: center;

  & header {
    display: flex;
    z-index: 1;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: fit-content;
    padding: 20px 3% 5%;

    background-image: url(../../../public/images/backgrounds/red-bg-top.svg);
    background-size: 80%;
    background-position: center;
    background-repeat: no-repeat;

    & a, & img, & > div {
      width: 42px;
      height: 36px;
    }
  }

  &__player {
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &__main {
    z-index: 1;
    position: relative;
    width: 100%;
    min-height: 50svh;
    padding: 0 1rem;

    margin-bottom: 7rem;
  }

  & h2 {
    @include  XL24;
  }

  & .h3 {
    @include XXS10;
    align-self: stretch;
    margin-top: 2%;
    margin-bottom: 3%;
    color: var(--Gulper, #9A9EA6);
    text-align: center;
  }
}
