@import "../../../components/App/App";

.binary-quiz-hoc {
  display: flex;
  flex-direction: column;

  --image-shadow-color: #000;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: fit-content;
    padding: 20px 3% 5%;

    background-image: url(../../../../public/images/backgrounds/red-bg-top.svg);
    background-size: 80%;
    background-position: center;
    background-repeat: no-repeat;

    & a, & img, & > div {
      width: 42px;
      height: 36px;
    }
  }

  &__main {
    &__top {
      &__label {
        text-align: center;
      }
  
      &__title {
        padding: 0 .75rem;
      }
    }

    &__image {
      position: relative;

      margin: calc(min(2rem, 1svh)) 0;

      box-shadow: 0 0 5rem var(--image-shadow-color);

      &::after {
        content: '';
        position: absolute;
        left: 0;
        width: 100%;
        height: 100%;
        box-shadow:
          inset 0 -2rem 4rem 1rem var(--image-shadow-color),
          inset 0 2rem 4rem 1rem var(--image-shadow-color);
      }

      & img {
        object-fit: cover;
        width: 100%;
        height: 45svh;
        max-height: 50%;
      }
    }
  }

  &__footer {
    width: 100%;
    max-width: 450px;
    left: 50%;
    translate: -50%;
    position: fixed;
    bottom: 1.5rem;
    z-index: 2;

    &__question {
      text-align: center;
      font-size: 1.25rem;
      color: var(--Shark, #FFF);
    }

    &__answer-buttons {
      display: flex;
      width: 100%;
      margin-top: 1rem;
      padding: 0 1rem;

      & button {
        @include  MD16;
        width: 50%;
        min-height: 48px;
        padding: 12px 20px;
        justify-content: center;
        align-items: center;
        border-radius: 8px;
        background: var(--Shark, #FFF);
        margin-left: .5rem;
        margin-right: .5rem;
        margin-bottom: 5%;
        font-weight: 540;
      }
    }
  } 

  & h2 {
    @include  XL24;
    letter-spacing: -0.02em;
  }

  & h3 {
    @include  XXS10;
    align-self: stretch;
    margin-top: 2%;
    color: var(--Gulper, #9A9EA6);
    margin-bottom: 4%;
  }
}
