.terms-privacy-settings {
  align-self: stretch;
  margin-top: 2%;
  margin-bottom: 4%;

  color: #808080;

  text-align: center;
  font-family: Inter;
  font-size: .75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0.12px;

  & > a[href] {
    text-decoration: underline;
    color: inherit;
  }
}
