.ui-proposition {
  display: flex;
  align-items: center;

  &__icon {
    img {
      width: 2.5rem;
    }
  }

  &__text {
    margin-left: .5rem;

    &__title {
      margin-bottom: .125rem;
      color: var(--Shark, #FFF);
    }

    &__description {
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
      letter-spacing: -0.05em;

      color: var(--Gulper, #9A9EA6);
    }
  }
}
