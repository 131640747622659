.app-download-button {
  display: flex;
  align-items: center;

  border-radius: .5rem;

  background-color: #fff;

  padding: .5rem 1.5rem;

  &__right-block {
    text-align: start;
    margin-left: .5rem;
    &__top {
      font-size: .5rem;
      font-weight: 500;
      line-height: .5rem;
      letter-spacing: 0.1em;
      
    }
    &__bottom {
      font-size: 1rem;
      font-weight: 500;
      line-height: 1rem;
    }
  }
}
